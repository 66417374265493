import React, { Component } from 'react';
import differenceInYears from "date-fns/differenceInYears";
import DialogContent from "@material-ui/core/DialogContent";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextFieldValidator from "../TextFieldValidator";
import userStore from "../../../store/UserStore";
import {withSnackbar} from "notistack";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers';
import TextField from "@material-ui/core/TextField";
import {createTheme, withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import AdornmentPasswordField from "../../LoginForm/AdornmentPasswordField";
import Grid from "@material-ui/core/Grid";
import DoneIcon from "@material-ui/core/SvgIcon/SvgIcon";
import SaveIcon from "@material-ui/icons/Save";
import AddPhotoDropzoneArea from "../AddPhotoDropzoneArea";
import Container from "@material-ui/core/Container";
import {NotificationManager} from "react-notifications";
import {Trans} from "react-i18next";
import apiStore from "../../../store/ApiStore";

const styles = (theme) => ({
	root: {
		position: 'relative'
	},
	schoolnumber: {
		display: 'block',
		border: 'solid #c4c4c4 1px',
		borderRadius: 4,
		padding: '18.5px 14px',
		width: '100%',
		'& input:first-child': {
			float: 'left',
			width: '48%',
			borderRight: 'solid #c4c4c4 1px',
		},
		'& input:last-child': {
			float: 'right',
			width: '48%'
		}
	},
	birthday: {
		border: 'solid #c4c4c4 1px',
		borderRadius: 4,
		paddingLeft: 10,
		marginBottom: 25,
		width: '100%',
		'&>div':{
			margin: 0,

		},
		'& input': {
			width: '100%'
		},
		'& .MuiInput-formControl': {
			margin: '9px 0',
			width: '100%'
		},
		'& .MuiInput-underline:after':{
			border:0
		},
		'& .MuiInput-underline:before':{
			border:0
		},
		'& .MuiInput-underline:hover:after':{
			border:0
		}
	},
	paper: {

	},
	formContainer: {
		boxShadow: 'none'
	},
	header: {
		textAlign: 'left',
		marginBottom: 40
	},
	pHeader: {
		borderBottom: '1px solid #E4E4E4',
		marginBottom: 20,
		fontFamily: 'Inter',
		fontSize: 20,
		lineHeight: 4,
		textAlign: 'left',
		color: '#898A9C'
	},
	pFooter:{
		textAlign: 'right',
	},
	textField: {
		marginBottom: 40
	},
	submit: {
		width: '100%',
		height: 64,
		background: '#1565D8',
		borderRadius: 6,
		marginTop: 40,
		marginBottom: 40
	},
	buttonregist: {
		cursor: 'pointer',
		color: '#1565D8'
	},
	form: {
		marginBottom: 40
	}
});

class ProfileForm extends Component {
	state = {
		errorRegistr: [],
		confirmIsSuccess: null,
		errors: {
			firstname: null,
			lastname: null,
			country: null,
			email: null,
			organization: null,
			fieldactivity: null,
			workposition: null,
			password: null,
			passwordrepeat: null,
			login: null,
			phone: null
		},
		firstname: null,
		middlename: null,
		lastname: null,
		country: null,
		organization: null,
		fieldactivity: null,
		workposition: null,
		birthday: null,
		age: null,
		gender: null,
		info: null,
		avatarUser: null,

		login: null,
		password: null,
		passwordrepeat: null,
		phone: null,
		email: null,
	};

	componentDidMount() {
		let userInfo = userStore.getUserInfo();
		// console.log("componentDidMount, userInfo: ", userInfo);
		let info = userInfo['manager'];// ?? uid, parent_uid, firstname, and so on
		info['avatarUser'] = userInfo['avatarUser'];
		info['manager'] = userInfo['manager'];
		info['user'] = userInfo['user'];
		info['email'] = userInfo['user'] ? userInfo['user']['email'] : userInfo['email'];
		info['phone'] = userInfo['user'] ? userInfo['user']['phone'] : userInfo['phone'];
		info['login'] = userInfo['user'] ? userInfo['user']['login'] : userInfo['login'];
		info['password'] = null;

		// console.log("componentDidMount, info: ", info);
		this.setState(info);
	}

	handleSubmit = (e) => {
		e.preventDefault();
		// console.log("handleSubmit, password, passwordrepeat: ", this.state.password, this.state.passwordrepeat);
		const needCompare = (this.state.password != null && this.state.password.length > 0)
			|| (this.state.passwordrepeat != null && this.state.passwordrepeat.length > 0);
		const comparing = needCompare ? this.state.password.localeCompare(this.state.passwordrepeat) : 0;
		// console.log("handleSubmit, comparing: ", comparing);
		if (comparing !== 0) {
			NotificationManager.error(<Trans i18nKey={"ErrorWithComparePassword"} >{"ErrorWithComparePassword"}</Trans>);
		} else {
			let regFormData = {
					firstname: this.state.firstname,
					middlename: this.state.middlename,
					lastname: this.state.lastname,
					country: this.state.country,
					organization: this.state.organization,
					fieldactivity: this.state.fieldactivity,
					workposition: this.state.workposition,
					birthday: this.state.birthday,
					age: this.state.age,
					gender: this.state.gender,
					info: this.state.info,

					login: this.state.login,
					password: this.state.password,
					phone: this.state.phone,
					email: this.state.email,
			};
			//console.log("regFormData: ", regFormData);
			userStore.updateUser(regFormData).then((result) => {
				// console.log("updateUser, result: ", result);
				if(result.statusCode > 300) {
					NotificationManager.error(result.message, <Trans i18nKey={"SentMessageError"}>{"SentMessageError"}</Trans>);
				} else {
					userStore.setUserInfo(result);
					userStore.saveUserInfo(result);
					NotificationManager.success('Изменения сохранены успешно!');
					NotificationManager.success(<Trans i18nKey={"SentMessageSuccessed"}>{"SentMessageSuccessed"}</Trans>);
				}
			});
		}
	};

	textChange = (event) => {
		let val = {};
		val[event.target.name] = event.target.value;
		// console.log(val);
		this.setState(val);
	};

/*
	formValid = (event) => {
		let valid = true;
		let checkInput = [
			'fio',
			'phone',
			'email',
			'password',
		];
		for(let key of checkInput){
			if(!this.state[key]){
				valid = false;
			}
		}
		// console.log(this.state);
		// console.log(valid);
		return valid;
	}
*/


	render() {
		const {classes} = this.props;
		return (
			<form  onSubmit={this.handleSubmit} className={classes.form} >
				<div className={classes.paper} >
					<Container>
						<Grid item xs={12} sm={12} md={12} lg={12} >
							<Typography component="h2" variant="h5" className={classes.header} color={""}>
								<b><Trans i18nKey={"ProfileOwner"} >ProfileOwner</Trans> </b>
								({this.state.parent_uid
								 ? "parent uid: "  + this.state.parent_uid + ")"
								 : 	"uid: "  + this.state.uid + ")"}
								)
							</Typography>
						</Grid>
					</Container>

					<Grid container spacing={2}>

						<Grid item xs={12} sm={6} md={4} lg={4} >
							<div style={{marginBottom: 15}}><b><Trans i18nKey={"Avatar"} >Avatar</Trans>*</b></div>
							<AddPhotoDropzoneArea
								avatar={this.state.avatarUser}
								acceptedFiles={["image/*"]}
								updateValue={(file) => {
									if (file.length !== 0) {
										apiStore.addAvatarUser(file[0] )
											.then((res) => {
												if (res.ok) {
													this.props.update(this.state.user?.id);
												} else {
													NotificationManager.error(`Ошибка добавления Аватара!`, 'Ошибка');
												}
											})
									}
								}}
							/>

							<div style={{marginBottom: 15, marginTop: 15}}><b><Trans i18nKey={"Login"} >Login</Trans>*</b></div>
							<TextField
								error={this.state.errorRegistr['login']?true:false}
								helperText={this.state.errorRegistr['login']}
								required
								onChange={this.textChange}
								value={this.state.login}
								style={{marginBottom: 15}}
								variant="outlined"
								id = "login"
								name = "login"
								label = {<Trans i18nKey={"Login"} >Login</Trans>}
								type = "text"
								fullWidth
							/>
							<div style={{marginBottom: 15}}><b><Trans i18nKey={"Password"} >Password</Trans></b></div>
							<AdornmentPasswordField
								error={this.state.errorRegistr['password']?true:false}
								helperText={this.state.errorRegistr['password']}
								name="password"
								onChange={this.textChange}
								value={this.state.password}
								label = {<Trans i18nKey={"Password"} >Password</Trans>}
								style={{width:'100%', marginBottom: 15}}
							/>
							<div style={{marginBottom: 10}}><b><Trans i18nKey={"RepeatPassword"} >RepeatPassword</Trans></b></div>
							<AdornmentPasswordField
								error={this.state.errorRegistr['passwordrepeat']?true:false}
								helperText={this.state.errorRegistr['passwordrepeat']}
								name="passwordrepeat"
								onChange={this.textChange}
								value={this.state.passwordrepeat}
								label = {<Trans i18nKey={"RepeatPassword"} >RepeatPassword</Trans>}
								style={{width:'100%', marginBottom: 15}}
							/>
						</Grid>

						<Grid item xs={12} sm={6} md={8} lg={8} >

							<div style={{marginBottom: 15, marginTop: 5}}><b><Trans i18nKey={"LastName"} >LastName</Trans>*</b></div>
							<TextField
								error={this.state.errorRegistr['lastname']?true:false}
								helperText={this.state.errorRegistr['lastname']}
								required
								onChange={this.textChange}
								value={this.state.lastname}
								style={{marginBottom: 15}}
								variant="outlined"
								id = "lastname"
								name = "lastname"
								label = {<Trans i18nKey={"LastName"} >LastName</Trans>}
								type = "text"
								fullWidth
							/>

							<Grid container  spacing={2} >
								<Grid item xs={12} md={6} lg={6} >
									<div style={{marginBottom: 15}}><b><Trans i18nKey={"FirstName"} >FirstName</Trans>*</b></div>
									<TextField
										error={this.state.errorRegistr['firstname']?true:false}
										helperText={this.state.errorRegistr['firstname']}
										required
										onChange={this.textChange}
										value={this.state.firstname}
										style={{marginBottom: 15}}
										variant="outlined"
										id = "firstname"
										name = "firstname"
										label = {<Trans i18nKey={"FirstName"} >FirstName</Trans>}
										type = "text"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={6} >
									<div style={{marginBottom: 15}}><b><Trans i18nKey={"MiddelName"} >MiddelName</Trans>*</b></div>
									<TextField
										error={this.state.errorRegistr['middlename']?true:false}
										helperText={this.state.errorRegistr['middlename']}
										required
										onChange={this.textChange}
										value={this.state.middlename}
										style={{marginBottom: 15}}
										variant="outlined"
										id = "middlename"
										name = "middlename"
										label = {<Trans i18nKey={"MiddelName"} >MiddelName</Trans>}
										type = "text"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={6} >
									<div style={{marginBottom: 15}}><b><Trans i18nKey={"WorkEmail"} >WorkEmail</Trans></b></div>
									<TextField
									error={this.state.errorRegistr['email']?true:false}
									helperText={this.state.errorRegistr['email']}
									onChange={this.textChange}
									value={this.state.email}
									style={{marginBottom: 15}}
									variant="outlined"
									id = "email"
									name = "email"
									label = {<Trans i18nKey={"Email"} >Email</Trans>}
									type = "text"
									fullWidth
								/>
								</Grid>
								<Grid item xs={12} md={6} lg={6} >
									<div style={{marginBottom: 15}}><b><Trans i18nKey={"Phone"} >Phone</Trans></b></div>
									<TextField
									error={this.state.errorRegistr['phone']?true:false}
									helperText={this.state.errorRegistr['phone']}
									onChange={this.textChange}
									value={this.state.phone}
									style={{marginBottom: 15}}
									variant="outlined"
									id = "phone"
									name = "phone"
									label = {<Trans i18nKey={"Phone"} >Phone</Trans>}
									type = "text"
									fullWidth
								/>
								</Grid>
								<Grid item xs={12} md={6} lg={6} >
									<div style={{marginBottom: 15}}><b><Trans i18nKey={"Organization"} >Organization</Trans></b></div>
									<TextField
									error={this.state.errorRegistr['organization']?true:false}
									helperText={this.state.errorRegistr['organization']}
									onChange={this.textChange}
									value={this.state.organization}
									style={{marginBottom: 15}}
									variant="outlined"
									id = "organization"
									name = "organization"
									label = {<Trans i18nKey={"Organization"} >Organization</Trans>}
									type = "text"
									fullWidth
								/>
								</Grid>
									<Grid item xs={12} md={6} lg={6} >
									<div style={{marginBottom: 15}}><b><Trans i18nKey={"Position"} >Position</Trans></b></div>
								<TextField
									error={this.state.errorRegistr['workposition']?true:false}
									helperText={this.state.errorRegistr['workposition']}
									onChange={this.textChange}
									value={this.state.workposition}
									style={{marginBottom: 15}}
									variant="outlined"
									id = "workposition"
									name = "workposition"
									label = {<Trans i18nKey={"Position"} >Position</Trans>}
									type = "text"
									fullWidth
								/>
							</Grid>
						</Grid>

							<div style={{marginBottom: 15}}><b><Trans i18nKey={"FieldOfActivity"} >FieldOfActivity</Trans></b></div>
							<TextField
								error={this.state.errorRegistr['fieldactivity']?true:false}
								helperText={this.state.errorRegistr['fieldactivity']}
								onChange={this.textChange}
								value={this.state.fieldactivity}
								style={{marginBottom: 15}}
								variant="outlined"
								id = "fieldactivity"
								name = "fieldactivity"
								label = {<Trans i18nKey={"FieldOfActivity"} >FieldOfActivity</Trans>}
								type = "text"
								fullWidth
							/>
							<div style={{marginBottom: 15}}><b><Trans i18nKey={"Country"} >Country</Trans></b></div>
							<TextField
								error={this.state.errorRegistr['country']?true:false}
								helperText={this.state.errorRegistr['country']}
								onChange={this.textChange}
								value={this.state.country}
								style={{marginBottom: 15}}
								variant="outlined"
								id = "country"
								name = "country"
								label = {<Trans i18nKey={"Country"} >Country</Trans>}
								type = "text"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={12}   >
							<div style={{textAlign: 'center'}}>
								<Button type="submit" variant="contained" color="primary" size="large" >
									<Trans i18nKey={"Save"} >Save</Trans>
								</Button>
							</div>
						</Grid>
					</Grid>
				</div>
			</form>
		);
	}
}


export default withStyles(styles, {withTheme: true})(ProfileForm);
