import React,{Component} from "react";
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import bitmap from "../../../static/img/bitmap.png";
import smartphone from "../../../static/img/smartphone.png";
import googleplay from "../../../static/img/google_play.png";
import appstore from "../../../static/img/app_store.png";
import rustore from "../../../static/img/rustore.png";
import rectangle from "../../../static/img/rectangle.png";
import banner_analytic from "../../../static/img/analytics_eng.png";
import banner_voiceprint from "../../../static/img/voiceprint_eng.png";
import banner_voiceverify from "../../../static/img/voiceverify_eng.png";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import {withStyles} from "@material-ui/core";
import {useStyles} from "../styles";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";

SwiperCore.use([Navigation]);

class Eng extends Component{


    render() {
        const {classes} = this.props;

        return <div>

            <section className={classes.sectionstartfree}>
                <Container >
                    <Grid container spacing={5} >
                        <Grid item xs={12} md={5} lg={5} >
                            <h1>Voice Authentication</h1>
                            <p>
                                > Forget passwords, just speak<br/>
                                > Text-independent<br/>
                                > Language-independent<br/>
                                > Verify with not less 7 seconds of voice example<br/>
                                > Easy integration in your mobile App (iOS/Android)<br/>
                            </p>
                            <br/><br/>
                            <Link to="/register" >
                                <Button  variant="contained" className={classes.startbutton} >
                                    <Trans i18nKey={"SignUp"} >{"SignUp"}</Trans>
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={7} lg={7} style={{textAlign:'right', position: 'relative'}} >
                            <div className={classes.circle4}></div>
                            <div className={classes.circle3}></div>
                            <div className={classes.circle2}></div>
                            <div className={classes.circle1}></div>
                            <img src={bitmap} alt="" />
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionslider1}>
                <Container >
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={12} lg={12} >
                            <Swiper
                                modules={[Navigation, Pagination]}
                                className={classes.swiper}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation
                                pagination={{ clickable: true }}
                            >
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={6} lg={6} style={{textAlign:'left', position: 'relative'}} >
                                                <img src={rectangle} alt="" />
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <h1>What is Speaker-ID ?</h1>
                                                <p>
                                                    Speaker-ID cloud service is based on the technology of voice biometrics which it allows to compare two audio records and determine if they correspond to the same person to verify their identity.
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={6} lg={6} style={{textAlign:'left', position: 'relative'}} >
                                                <img src={rectangle} alt="" />
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <h1>How does it work ?</h1>
                                                <p>
                                                    Speaker-ID engine is based on duel comparison spectra features of unknown voice with the spectra features of target voice.
                                                    Spectra features are calculated with provision of dynamic determinations of channel distortion level and external hindrances and noises.
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={6} lg={6} style={{textAlign:'left', position: 'relative'}} >
                                                <img src={rectangle} alt="" />
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <h1>General Features</h1>
                                                <p>
                                                    > Operation with low SNR<br/>
                                                    > Fast adaptation to changing of channel distortion and external noises<br/>
                                                    > Voiceprint  with not less 15 seconds of target voice<br/>
                                                    > Verify with not less 7 seconds of voice example<br/>
                                                    > Text-independent and Language-independent<br/>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                            </Swiper>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionslider2}>
                <Container >
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={12} lg={12} >
                            <Swiper
                                className={classes.swiper}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation={true}
                            >
                                <SwiperSlide>
                                    {/* <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5}  style={{textAlign:'left', position: 'relative'}} >
                                                <img src={banner1} alt="" />
                                                <h1>Voice Analytic</h1>
                                                <p>
                                                    So you have your new digital camera and clicking away to glory anything and everything in sight. Now you want to print them and you need the best photo
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <img src={banner2} alt="" />
                                                <h1>Voice Authentication 2</h1>
                                                <p>
                                                    So you have your new digital camera and clicking away to glory anything and everything in sight. Now you want to print them and you need the best photo
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container> */}
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5}  style={{textAlign:'left', position: 'relative'}}  >
                                                <h1>Voice prints monitoring</h1>
                                                <p>
                                                    > voiceprints monitoring for registered users.<br/>
                                                    > llistening to audio records of voiceprints.<br/>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6} >
                                                <img src={banner_voiceprint}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    {/*<Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5}  style={{textAlign:'left', position: 'relative'}} >
                                                <img src={banner1} alt="" />
                                                <h1>Voice Authentication 3</h1>
                                                <p>
                                                    So you have your new digital camera and clicking away to glory anything and everything in sight. Now you want to print them and you need the best photo
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <img src={banner2} alt="" />
                                                <h1>Voice Authentication 4</h1>
                                                <p>
                                                    So you have your new digital camera and clicking away to glory anything and everything in sight. Now you want to print them and you need the best photo
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container> */}
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5} style={{textAlign:'left', position: 'relative'}}  >
                                                <h1>Voice authentications monitoring</h1>
                                                <p>
                                                    > voice authentications monitoring for registered users.<br/>
                                                    > listening to audio records of voice authentications.<br/>
                                                    > blocking a user if phishing is suspected.<br/>
                                                    > regulated level of threshold for acceptance (FAR) and rejection (FRR) errors.<br/>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6} >
                                                <img src={banner_voiceverify}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    {/* <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5}  style={{textAlign:'left', position: 'relative'}} >
                                                <img src={banner1} alt="" />
                                                <h1>Voice Authentication 5</h1>
                                                <p>
                                                    So you have your new digital camera and clicking away to glory anything and everything in sight. Now you want to print them and you need the best photo
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <img src={banner2} alt="" />
                                                <h1>Voice Authentication 6</h1>
                                                <p>
                                                    So you have your new digital camera and clicking away to glory anything and everything in sight. Now you want to print them and you need the best photo
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container> */}
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5}  style={{textAlign:'left', position: 'relative'}}  >
                                                <h1>Voice analytics</h1>
                                                <p>
                                                    > of registered users for the selected time period.<br/>
                                                    > of voiceprints for the selected time period.<br/>
                                                    > of voice authentications (acceptances, rejections) for the selected time period.<br/>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6} >
                                                <img src={banner_analytic}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                            </Swiper>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionmarket}>
                <Container >
                    <Grid container spacing={5} >
                        <Grid item xs={12} md={5} lg={5} >
                            <h2>To test now "Speaker-id check" app</h2>
                            <br/><br/>
                            <a href="https://play.google.com/store/apps/details?id=com.grittec.speakerid" target="_blank" rel="noreferrer" >
                                <img src={googleplay} alt="" />
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="https://apps.rustore.ru/app/com.grittec.speakerid" target="_blank" rel="noreferrer" >
                                <img src={rustore} alt="" />
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <img src={appstore} alt="" />
                            <br/><br/>
                        </Grid>
                        <Grid item xs={12} md={7} lg={7} style={{textAlign:'right', position: 'relative'}} >
                            <img src={smartphone} alt="" />
                        </Grid>
                    </Grid>
                </Container>
            </section>
        </div>
    };
}

export default withStyles(useStyles, {withTheme: true})(Eng);