import React,{Component} from "react";
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import bitmap from "../../../static/img/bitmap.png";
import smartphone from "../../../static/img/smartphone_ru.png";
import googleplay from "../../../static/img/google_play_ru.png";
import appstore from "../../../static/img/app_store_ru.png";
import rustore from "../../../static/img/rustore_ru.png";
import rectangle from "../../../static/img/rectangle.png";
import banner_analytic from "../../../static/img/analytics_ru.png";
import banner_voiceprint from "../../../static/img/voicepront_ru.png"
import banner_voiceverify from "../../../static/img/voiceverify_ru.png";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import {withStyles} from "@material-ui/core";
import {useStyles} from "../styles";
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";

SwiperCore.use([Navigation]);

class Rus extends Component{


    render() {
        const {classes} = this.props;

        return <div>

            <section className={classes.sectionstartfree}>
                <Container >
                    <Grid container spacing={5} >
                        <Grid item xs={12} md={5} lg={5} >
                            <h1>Голосовая аутентификация</h1>
                            <p>
                                > Забудьте пароли, просто говорите<br/>
                                > Не зависит от текста и языка<br/>
                                > Длительность голосового образца для его верификации не менее 7 секунд<br/>
                                > Простота встраивания в мобильное приложение (iOS/Android)<br/>
                            </p>
                            <br/><br/>
                            <Link to="/register" >
                                <Button  variant="contained" className={classes.startbutton} >
                                    <Trans i18nKey={"SignUp"} >{"SignUp"}</Trans>
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={7} lg={7} style={{textAlign:'right', position: 'relative'}} >
                            <div className={classes.circle4}></div>
                            <div className={classes.circle3}></div>
                            <div className={classes.circle2}></div>
                            <div className={classes.circle1}></div>
                            <img src={bitmap} alt="" />
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionslider1}>
                <Container >
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={12} lg={12} >
                            <Swiper
                                className={classes.swiper}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation={true}
                            >
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={6} lg={6} style={{textAlign:'left', position: 'relative'}} >
                                                <img src={rectangle} alt="" />
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <h1>Что такое Speaker-ID ?</h1>
                                                <p>
                                                    Speaker-ID это облачный сервис основанный на технологии голосовой биометрии, который позволяет сравнить две аудио записи
                                                    с голосом человека и определить соответствуют ли они одному и тому же человеку, чтобы однозначно его идентифицироать.
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={6} lg={6} style={{textAlign:'left', position: 'relative'}} >
                                                <img src={rectangle}  alt="" />
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <h1>Как это работает ?</h1>
                                                <p>
                                                    Технология Speaker-ID основана на парном сравнение спектральных параметров аудио записи
                                                    неизвестного голоса с спектральными параметрами аудио записи целевого голоса.
                                                    Спектральные параметры вычисляются с учетом динамического определения уровня канальных искажений и внешних помех и шумов.
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={6} lg={6} style={{textAlign:'left', position: 'relative'}} >
                                                <img src={rectangle}  alt="" />
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <h1>Достоинства</h1>
                                                <p>
                                                    > Операции с низким уровнем SNR<br/>
                                                    > Быстрая адаптация к канальным искажениям и внешним шумам<br/>
                                                    > Длительность голосового образца для целевого голоса не менее 15 секунд<br/>
                                                    > Длительность голосового образца для его верификации не менее 7 секунд<br/>
                                                    > Не зависит от текста и языка<br/>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                ...
                            </Swiper>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionslider2}>
                <Container >
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={12} lg={12} >
                            <Swiper
                                className={classes.swiper}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation={true}
                            >
                                <SwiperSlide>
                                    {/*
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5}  style={{textAlign:'left', position: 'relative'}} >
                                                <img src={banner1}  alt="" />
                                                <h1>Мониторинг регистрации голосовых отпечатков</h1>
                                                <p>
                                                    Мониторинг регистрации голосовых отпечатков зарегистрированных пользователей в системе.
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <img src={banner2}  alt="" />
                                                <h1>Мониторинг регистрации голосовых отпечатков</h1>
                                                <p>
                                                    Прослушивание аудио записи, используемые для построения голосового отпечатка
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container> */}
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5} style={{textAlign:'left', position: 'relative'}}  >
                                                <h1>Мониторинг голосовых отпечатков</h1>
                                                <p>
                                                    > мониторинг голосовых отпечатков зарегистрированных пользователей.<br/>
                                                    > прослушивание аудиозаписей голосовых отпечатков.<br/>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6} >
                                                <img src={banner_voiceprint}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    {/*
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5}  style={{textAlign:'left', position: 'relative'}} >
                                                <img src={banner1}  alt="" />
                                                <h1>Мониторинг голосовых аутенфикаций</h1>
                                                <p>
                                                    Мониторинг голосовых аутенфикаций зарегистрированных пользователей в системе.
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <img src={banner2}  alt="" />
                                                <h1>Мониторинг голосовых аутенфикаций</h1>
                                                <p>
                                                    Прослушивание аудио записи, используемая для голосовой аутенфикации.
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container> */}
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5} style={{textAlign:'left', position: 'relative'}}  >
                                                <h1>Мониторинг голосовых аутентификаций</h1>
                                                <p>
                                                    > мониторинг голосовых аутентификаций зарегистрированных пользователей.<br/>
                                                    > прослушивание аудиозаписей голосовых аутентификаций.<br/>
                                                    > блокировка пользователя при подозрении на фишинг.<br/>
                                                    > регулируемый уровень порогов ошибок принятия (FAR) и отказа (FRR).<br/>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6} >
                                                <img src={banner_voiceverify}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    {/*
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5}  style={{textAlign:'left', position: 'relative'}} >
                                                <img src={banner1}  alt="" />
                                                <h1>Голосовая аналитика</h1>
                                                <p>
                                                    > Стастика количества зарегистрированных пользователей за выбранный период времени.<br/>
                                                    > Стастика регистрации голосовых отпечатков по всем зарегистрированных пользователей за выбранный период времени.<br/>
                                                    > Стастика голосовых аутенфикаций (принятий и отказов) по всем зарегистрированных пользователей за выбранный период времени.
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <img src={banner2}  alt="" />
                                                <h1>Голосовая аналитика</h1>
                                                <p>
                                                    So you have your new digital camera and clicking away to glory anything and everything in sight. Now you want to print them and you need the best photo
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container> */}
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5} style={{textAlign:'left', position: 'relative'}}  >
                                                <h1>Голосовая аналитика</h1>
                                                <p>
                                                    > зарегистрированных пользователей за выбранный период времени.<br/>
                                                    > голосовых отпечатков за выбранный период времени.<br/>
                                                    > голосовых аутентификаций (принятий, отказов) за выбранный период времени.<br/>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6} >
                                                <img src={banner_analytic}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                ...
                            </Swiper>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionmarket}>
                <Container >
                    <Grid container spacing={5} >
                        <Grid item xs={12} md={5} lg={5} >
                            <h2>Протестируйте сейчас "Speaker-id check"</h2>
                            <br/><br/>
                            <a href="https://play.google.com/store/apps/details?id=com.grittec.speakerid" target="_blank" rel="noreferrer" >
                                <img src={googleplay} alt="" />
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="https://apps.rustore.ru/app/com.grittec.speakerid" target="_blank" rel="noreferrer" >
                                <img src={rustore} alt="" />
                            </a>
                            &nbsp;&nbsp;&nbsp;
                                <img src={appstore} alt="" />
                            <br/><br/>
                        </Grid>
                        <Grid item xs={12} md={7} lg={7} style={{textAlign:'right', position: 'relative'}} >
                            <img src={smartphone} alt="" />
                        </Grid>
                    </Grid>
                </Container>
            </section>
        </div>;
    };
}


export default withStyles(useStyles, {withTheme: true})(Rus);